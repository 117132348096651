const actions = {
  SET_STATE: 'subscriptions/SET_STATE',
  REMOVE_DATA: 'subscriptions/REMOVE_DATA',
  SHOW_CREATE: 'subscriptions/SHOW_CREATE',
  HIDE_CREATE: 'subscriptions/HIDE_CREATE',
  SHOW_UPDATE: 'subscriptions/SHOW_UPDATE',
  HIDE_UPDATE: 'subscriptions/HIDE_UPDATE',
  SHOW_DELETE: 'subscriptions/SHOW_DELETE',
  HIDE_DELETE: 'subscriptions/HIDE_DELETE',

  LOAD_ALL: 'subscriptions/LOAD_ALL',
  CREATE: 'subscriptions/CREATE',
  UPDATE: 'subscriptions/UPDATE',
  REMOVE: 'subscriptions/REMOVE',
}

export default actions
