import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Input, Form, Button, Switch } from 'antd'
import { injectIntl } from 'react-intl'
import CustomerAction from 'redux/customers/actions'
// import MaskedInput from 'antd-mask-input'
// import { phoneNumberE164, phoneNumberNational } from 'utils/parser'
import Loading from 'components/app/common/Loading'
import CardTitle from 'components/app/common/CardTitle'

const mapStateToProps = ({ dispatch, customers, user }) => ({
  dispatch,
  customers,
  user,
})

const UpdateUser = ({ customers, intl: { formatMessage } }) => {
  const dispatch = useDispatch()
  const path = window.location.pathname

  const userId = path.replace('/manage/customer-info/', '')

  console.log('23', customers)

  const onFinish = values => {
    // if (values.mobile) values.mobile = phoneNumberE164(values.mobile)
    dispatch({
      type: CustomerAction.UPDATE_CUSTOMER_DETAIL,
      payload: {
        updatedData: values,
        UseridSel: userId,
      },
    })
  }

  const [form] = Form.useForm()

  const toInputLowercase = e => {
    e.target.value = e.target.value.toLowerCase()
  }

  useEffect(() => {
    const apiCallInfo = customers?.detailInfo
    if (apiCallInfo) {
      form.setFieldsValue({
        first_name: customers?.detailInfo?.first_name,
        last_name: customers?.detailInfo?.last_name,
        emailid: customers?.detailInfo?.emailid,
        status: customers?.detailInfo?.status,
        mobile_number: customers?.detailInfo?.mobile_number,
        // mobile_number: phoneNumberNational(customers?.detailInfo?.mobile_number),
      })
    }
  }, [form, customers])

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            form={form}
            initialValues={
              {
                first_name: customers?.detailInfo?.first_name,
                last_name: customers?.detailInfo?.last_name,
                emailid: customers?.detailInfo?.emailid,
                mobile_number: customers?.detailInfo?.mobile_number,
              }
            }
          >
            <CardTitle />
            <div className="row">
              <div className="col-12" hidden={customers?.detailInfo}>
                <Loading />
              </div>
            </div>
            <div className="row" hidden={!customers.detailInfo}>
              <div className="col-md-4">
                <Form.Item
                  name="first_name"
                  label={formatMessage({ id: 'form.label.first_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.first_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="first_name"
                  label={formatMessage({ id: 'form.label.first_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.first_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="last_name"
                  label={formatMessage({ id: 'form.label.last_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.last_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>

              <div className="col-md-4">
                <Form.Item
                  name="emailid"
                  label={formatMessage({ id: 'form.label.email' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.email.required' }) },
                    { type: 'email', message: formatMessage({ id: 'form.error.email.type' }) },
                    { max: 64, message: formatMessage({ id: 'form.error.email.max' }) },
                  ]}
                >
                  <Input
                    type="email"
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.email' })}
                    maxLength={64}
                    onInput={toInputLowercase}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="mobile_number"
                  label={formatMessage({ id: 'form.label.mobile_number' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.mobile_number' })}
                    maxLength={64}
                  />
                </Form.Item>
                {/* <Form.Item
                  name="mobile_number"
                  label={formatMessage({ id: 'form.label.phoneoptional' })}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        const phoneNumber = phoneNumberNational(value)
                        if (!value || (phoneNumber && phoneNumber.length === 14)) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(formatMessage({ id: 'form.error.phone.invalid' })),
                        )
                      },
                    }),
                  ]}
                >
                  <MaskedInput
                    mask="(111) 111-1111"
                    placeholder={formatMessage({ id: 'form.placeholder.mobile' })}
                    size="large"
                  />
                </Form.Item> */}
              </div>

              <div className="col-md-4">
                <Form.Item
                  name="status"
                  label={formatMessage({ id: 'form.label.user_status' })}
                  valuePropName="checked"
                >
                  <Switch
                    checkedChildren={formatMessage({ id: 'action.active' })}
                    unCheckedChildren={formatMessage({ id: 'action.inactive' })}
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item className="text-right">
              <Button type="primary" htmlType="submit" size="large" loading={customers?.updating}>
                {formatMessage({ id: 'action.update' })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(UpdateUser))
