const actions = {
  SET_ERROR_STATE: 'user/SET_ERROR_STATE',
  SET_STATE: 'user/SET_STATE',
  LOGIN: 'user/LOGIN',
  REGISTER: 'user/REGISTER',
  RECOVER_PASSWORD: 'user/RECOVER_PASSWORD',
  RESET_PASSWORD: 'user/RESET_PASSWORD',
  LOAD_CURRENT_ACCOUNT: 'user/LOAD_CURRENT_ACCOUNT',
  LOGOUT: 'user/LOGOUT',
  CLEAR_USER: 'user/CLEAR_USER',
  SHOW_PASSWORD_MODAL: 'user/SHOW_PASSWORD_MODAL',
  HIDE_PASSWORD_MODAL: 'user/HIDE_PASSWORD_MODAL',
  CHANGE_PASSWORD: 'user/CHANGE_PASSWORD',
}

export default actions
