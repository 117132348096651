const actions = {
  SET_STATE: 'customers/SET_STATE',

  LOAD_ALL: 'customers/LOAD_ALL',
  SHOW_DETAILED_INFO: 'customers/SHOW_DETAILED_INFO',
  LOAD_CUSTOMER_INFO: 'customers/LOAD_CUSTOMER_INFO',
  UPDATE_CUSTOMER_DETAIL: 'customers/UPDATE_CUSTOMER_DETAIL',
  LOAD_DEVICE_DATA: 'customers/LOAD_DEVICE_DATA',
  LOAD_BANK_INFO: 'customers/LOAD_BANK_INFO',
  LOAD_VIDEO_LOGS: 'customers/LOAD_VIDEO_LOGS',
  GETVIDEOSFILTER: 'customers/GETVIDEOSFILTER',

  SHOW_WALLET: 'customers/SHOW_WALLET',
  HIDE_WALLET: 'customers/HIDE_WALLET',
  GET_WALLET: 'customers/GET_WALLET',
  LOAD_WALLET_HISTORY: 'customers/LOAD_WALLET_HISTORY',
  FILTER_WALLET_HISTORY: 'customers/FILTER_WALLET_HISTORY',
  UPDATE_CUSTOMER_BANK: 'customers/UPDATE_CUSTOMER_BANK',
}

export default actions
