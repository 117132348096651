import apiClient from 'services/axios'

const BASE_URL = 'api/subscription-web'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/get-subscription-plan`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function create(payload) {
  return apiClient
    .post(`${BASE_URL}/add-subscription-plan`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(UseridSel, updatedData) {
  return apiClient
    .put(`${BASE_URL}/update-subscription-plan/${UseridSel}`, updatedData)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function remove(subscriptionId) {
  return apiClient
    .put(`${BASE_URL}/delete-subscription-plan/${subscriptionId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
