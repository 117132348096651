const actions = {
  SET_STATE: 'payouts/SET_STATE',

  LOAD_ALL: 'payouts/LOAD_ALL',
  GET_PAYOUT_HISTORY: 'payouts/GET_PAYOUT_HISTORY',
  SHOW_HISTORY: 'payouts/SHOW_HISTORY',
  HIDE_HISTORY: 'payouts/HIDE_HISTORY',
  SHOW_MODAL: 'payouts/SHOW_MODAL',
  HIDE_MODAL: 'payouts/HIDE_MODAL',
  GET_CURRENT_STATUS: 'payouts/GET_CURRENT_STATUS',
  STATUS_CHANGE: 'payouts/STATUS_CHANGE',
}

export default actions
