import actions from './actions'

const initialState = {
  customerInfo: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,
  detailInfo: null,
  deviceInfo: null,
  videoInfo: null,
  walletInfo: null,
  searchCustomer: null,
  searchDevice: null,
  searchVideos: null,
  searchWallet: null,
  walletHistoryInfo: null,
  search: null,
  company_data: null,

  walletPoints: null,
  displayWalletDrawer: false,
  showing: false,
}

export default function customersReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SHOW_WALLET:
      return { ...state, selectedData: action.payload.customerInfo, displayWalletDrawer: true }
    case actions.HIDE_WALLET:
      return { ...state, selectedData: null, displayWalletDrawer: false }

    default:
      return state
  }
}
