import actions from './actions'

const initialState = {
  videoInfo: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,

  searchVideo: null,
  searchDevice: null,
  search: null,
  company_data: null,

  displayUploadVideo: false,
  uploading: false,

  displayRemoveVideo: false,
  removing: false,

  displayVideoCost: false,
  displaying: false,
}

export default function videosReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SHOW_UPLOAD:
      return { ...state, displayUploadVideo: true }
    case actions.HIDE_UPLOAD:
      return { ...state, selectedData: null, displayUploadVideo: false }
    case actions.SHOW_DELETE:
      return { ...state, selectedData: action.payload.videoInfo, displayRemoveVideo: true }
    case actions.HIDE_DELETE:
      return { ...state, selectedData: null, displayRemoveVideo: false }
    case actions.SHOW_COST_DRAWER:
      return { ...state, selectedData: action.payload.videoInfo, displayVideoCost: true }
    case actions.HIDE_COST_DRAWER:
      return { ...state, selectedData: null, displayVideoCost: false }

    default:
      return state
  }
}
