import apiClient from 'services/axios'

const BASE_URL = 'api/payout'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/get-payout`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadpayouthistory(payload) {
  return apiClient
    .get(`${BASE_URL}/get-payout-history/${payload?.payoutId}`,)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function getlateststatus(payload) {
  return apiClient
    .get(`${BASE_URL}/get-payout-status/${payload?.payoutId}`,)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function statuschange(payload) {
  return apiClient
    .post(`${BASE_URL}/update-status`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}





