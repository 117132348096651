import React, { useState, useEffect, Suspense } from 'react'
import { connect } from 'react-redux'
import { injectIntl, useIntl } from 'react-intl'
import CustomerAction from 'redux/customers/actions'
import { createStructuredSelector } from 'reselect'
import { selectCustomers, selectedWalletHistory } from 'redux/customers/selectors'
import { Input, Form, DatePicker, Table, Tag, Spin, Button } from 'antd'
import moment from 'moment'

const { Search } = Input

const mapStateToProps = () =>
  createStructuredSelector({
    customers: selectCustomers,
    selectedWallet: selectedWalletHistory,
  })

const Devicelist = ({ dispatch, selectedWallet, customers }) => {
  const { formatMessage, formatNumber } = useIntl()

  console.log('customers', customers)

  const onSearch = value => {
    dispatch({
      type: CustomerAction.SET_STATE,
      payload: { searchWallet: value },
    })
  }

  const [form] = Form.useForm()

  const [startTime, setstartTime] = useState()

  const path = window.location.pathname

  const userId = path.replace('/manage/customer-info/', '')

  const WalletApiDatas = selectedWallet

  console.log('userId', userId)

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: CustomerAction.LOAD_WALLET_HISTORY,
        payload: userId,
      }) // eslint-disable-next-line
  }, [dispatch])

  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const onClick = () => {
    form.resetFields()
    dispatch({
      type: CustomerAction.LOAD_WALLET_HISTORY,
      payload: userId,
    })
  }

  const onFinish = () => {
    dispatch({
      type: CustomerAction.FILTER_WALLET_HISTORY,
      payload: {
        userIdVal: userId,
        from: startTime.format('YYYY-MM-DD'),
      },
    })
  }

  const onChange = (dates, dateStrings) => {
    console.log('dates')
    console.log('From: ', dateStrings[0], ', to: ', dateStrings[1])

    if (dates) {
      setstartTime(dates)
    } else {
      console.log('Clear')
    }
  }

  const columns = [
    {
      title: formatMessage({ id: 'text.serialNo' }),
      key: 's_no',
      render: (text, record, index) => `${(page - 1) * pageSize + index + 1}.`,
    },
    {
      title: formatMessage({ id: 'text.points' }),
      dataIndex: 'points',
      key: 'points',
      render: text => (
        <strong>
          {formatNumber(text, {
            style: 'currency',
            currency: 'INR',
          })}
        </strong>
      ),
    },
    {
      title: formatMessage({ id: 'text.points_type' }),
      render: (text, record) => (
        <>
          {record?.points_type === 'videoads' && (
            <>
              <Tag color="green" style={{ textTransform: 'capitalize' }}>
                Video Ads
              </Tag>
            </>
          )}
          {record?.points_type === 'withdrawal' && (
            <>
              <Tag color="red" style={{ textTransform: 'capitalize' }}>
                Withdrawal
              </Tag>
            </>
          )}
        </>
      ),
    },
    // {
    //   title: formatMessage({ id: 'text.status' }),
    //   render: record => (
    //     <>
    //       {record?.status === true && (
    //         <>
    //           <Badge color="green" text={formatMessage({ id: 'text.active' })} />
    //         </>
    //       )}
    //       {record?.status === false && (
    //         <>
    //           <Badge color="red" text={formatMessage({ id: 'text.inactive' })} />
    //         </>
    //       )}
    //     </>
    //   ),
    // },
    {
      title: formatMessage({ id: 'text.walletCreated' }),
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: text => moment(text).format('MMM D, YYYY'),
    },
  ]

  const locale = {
    emptyText: formatMessage({ id: 'text.custommessage.WalletHistory' }),
  }

  return (
    <div>
      <div style={{ backgroundColor: 'white', padding: '10px', borderRadius: 'calc(7px - 1px)' }}>
        <div className="row">
          <div className="col-md-4">
            <Form.Item>
              <Search
                style={{ width: '100%' }}
                placeholder={formatMessage({ id: 'form.placeholder.WalletHistory.search' })}
                size="large"
                onChange={e => onSearch(e.target.value)}
                allowClear
              />
            </Form.Item>
          </div>
          <div className="col-md-4">
            <Form
              form={form}
              layout="vertical"
              hideRequiredMark
              // initialValues={{
              //   from: moment(leads?.leadfilterdata?.startDate),
              // }}
              onFinish={onFinish}
            >
              <div className="row">
                <div className="col-md-6">
                  <Form.Item name="taskDate">
                    <DatePicker format="YYYY-MM-DD" onChange={onChange} />
                  </Form.Item>
                </div>
                <div className="col-md-6">
                  <Form.Item>
                    <Button type="primary" htmlType="submit" size="small">
                      {formatMessage({ id: 'action.filtersearch' })}
                    </Button>
                    &nbsp;&nbsp;
                    <Button type="primary" size="small" onClick={onClick}>
                      {formatMessage({ id: 'action.clear' })}
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </Form>
          </div>
          <div className="col-md-4">
            <span style={{ fontSize: '20px', color: '#A52A2A' }}> Wallet:</span>
            <span style={{ fontSize: '20px', color: '#057AA7' }}>
              {' '}
              <b>{customers?.walletPoints}</b> INR
            </span>
          </div>
        </div>
        <div className="kit__utils__table">
          <Suspense fallback={<Spin />}>
            {WalletApiDatas?.length >= 0 ? (
              <Table
                dataSource={WalletApiDatas}
                columns={columns}
                locale={locale}
                rowKey="_id"
                pagination={{
                  current: page,
                  pageSize1: pageSize,
                  total: WalletApiDatas?.length || 0,
                  onChange: (current, newPageSize) => {
                    setPageSize(newPageSize)
                    setPage(pageSize !== newPageSize ? 1 : current)
                  },
                }}
              />
            ) : (
              <Table
                columns={columns}
                locale={locale}
                loading={{
                  indicator: (
                    <div>
                      <Spin />
                    </div>
                  ),
                }}
              />
            )}
          </Suspense>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(Devicelist))
