import { createSelector } from 'reselect'

export const selectCustomers = state => state?.customers

export const selectedCustomerData = createSelector([selectCustomers], customers => {
  const { searchCustomer, customerInfo } = customers
  if (searchCustomer && customerInfo) {
    return customerInfo.filter(
      r =>
        r?.business_name?.toLowerCase().includes(searchCustomer.toLowerCase()) ||
        r?.contact_name?.toLowerCase().includes(searchCustomer.toLowerCase()) ||
        r?.emailid?.toLowerCase().includes(searchCustomer.toLowerCase()),
    )
  }
  return customerInfo
})

export const selectedVideosdata = createSelector([selectCustomers], customers => {
  const { searchVideos, videoInfo } = customers
  if (searchVideos && videoInfo) {
    return videoInfo.filter(
      r =>
        r?.video_source?.toLowerCase().includes(searchVideos.toLowerCase()) ||
        r?.filename?.toLowerCase().includes(searchVideos.toLowerCase()) ||
        r?.watched_at?.toLowerCase().includes(searchVideos.toLowerCase()),
    )
  }
  return videoInfo
})

export const selectedWalletHistory = createSelector([selectCustomers], customers => {
  const { searchWallet, walletHistoryInfo } = customers
  if (searchWallet && walletHistoryInfo) {
    return walletHistoryInfo.filter(r =>
      r?.points_type?.toLowerCase().includes(searchWallet.toLowerCase()),
    )
  }
  return walletHistoryInfo
})
