const actions = {
  SET_STATE: 'videos/SET_STATE',

  LOAD_ALL: 'videos/LOAD_ALL',
  UPLOAD_VIDEO: 'videos/UPLOAD_VIDEO',
  SHOW_UPLOAD: 'videos/SHOW_UPLOAD',
  HIDE_UPLOAD: 'videos/HIDE_UPLOAD',
  SHOW_DELETE: 'videos/SHOW_DELETE',
  HIDE_DELETE: 'videos/HIDE_DELETE',
  SHOW_COST_DRAWER: 'videos/SHOW_COST_DRAWER',
  HIDE_COST_DRAWER: 'videos/HIDE_COST_DRAWER',
  REMOVE: 'videos/REMOVE',
  UPDATE_COST: 'videos/UPDATE_COST',
}

export default actions
