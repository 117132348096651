const actions = {
  SET_STATE: 'subrequest/SET_STATE',

  LOAD_ALL: 'subrequest/LOAD_ALL',
  UPDATE: 'subrequest/UPDATE',

  SHOW_INFO: 'subrequest/SHOW_INFO',
  HIDE_INFO: 'subrequest/HIDE_INFO',
  VALIDATE_REQUEST: 'subrequest/VALIDATE_REQUEST',
}

export default actions
