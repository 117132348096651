import { all, call, put, takeLatest } from 'redux-saga/effects'
import {
  loadAll,
  loadSingleCustomer,
  updateCustomer,
  loadcustomerdevice,
  loadcustomerbankdetails,
  showWallet,
  loadwallethistory,
  loadwallethistory2,
  loadcustomervideologs,
  loadcustomervideologs2,
  updateCustomerBank
} from 'api/customers'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { customerList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        customerInfo: customerList,
      },
    })
  }
}

export function* LOAD_CUSTOMER_INFO(payload) {
  const business_id = payload.payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(loadSingleCustomer, business_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { customerDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        detailInfo: customerDetail,
      },
    })
  }
}

export function* LOAD_DEVICE_DATA(payload) {
  const business_id = payload.payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(loadcustomerdevice, business_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { deviceDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        deviceInfo: deviceDetail,
      },
    })
  }
}

export function* LOAD_BANK_INFO(payload) {
  const business_id = payload.payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(loadcustomerbankdetails, business_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { bankDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        bankInfo: bankDetail,
      },
    })
  }
}


export function* LOAD_VIDEO_LOGS(payload) {
  const business_id = payload.payload
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(loadcustomervideologs, business_id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { videoDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        videoInfo: videoDetail,
      },
    })
  }
}

export function* GETVIDEOSFILTER(payload) {
  console.log('payload', payload)
  const business_id = payload.payload.userIdVal
  const filter_date = payload.payload.from
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: true,
    },
  })
  const { response } = yield call(loadcustomervideologs2, business_id, filter_date)
  yield put({
    type: actions.SET_STATE,
    payload: {
      loading: false,
    },
  })
  if (response) {
    const { videoDetail } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        videoInfo: videoDetail,
      },
    })
  }
}

export function* UPDATE_CUSTOMER_DETAIL({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { UseridSel, updatedData } = payload
  const { response } = yield call(updateCustomer, UseridSel, updatedData)
  const { customerDetail } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
      detailInfo: customerDetail,
    },
  })
}


export function* UPDATE_CUSTOMER_BANK({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { UseridSel, updatedData } = payload
  const { response } = yield call(updateCustomerBank, UseridSel, updatedData)
  const { bankDetail } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
      bankInfo: bankDetail,
    },
  })
}



export function* GET_WALLET({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      showing: true,
    },
  })
  const { response } = yield call(showWallet, payload)
  const { walletDetail } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      showing: false,
      walletPoints: walletDetail,
    },
  })
}

export function* LOAD_WALLET_HISTORY({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      showing: true,
    },
  })
  const { response } = yield call(loadwallethistory, payload)
  const { walletHistory, currentPoints } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      showing: false,
      walletHistoryInfo: walletHistory,
      walletPoints: currentPoints,
    },
  })
}

export function* FILTER_WALLET_HISTORY({ payload }) {
  console.log('192', payload)
  const business_id = payload.userIdVal
  const filter_date = payload.from
  yield put({
    type: actions.SET_STATE,
    payload: {
      showing: true,
    },
  })
  const { response } = yield call(loadwallethistory2, business_id, filter_date)
  const { walletHistory, currentPoints } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      showing: false,
      walletHistoryInfo: walletHistory,
      walletPoints: currentPoints,
    },
  })
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.LOAD_CUSTOMER_INFO, LOAD_CUSTOMER_INFO),
    takeLatest(actions.UPDATE_CUSTOMER_DETAIL, UPDATE_CUSTOMER_DETAIL),
    takeLatest(actions.LOAD_DEVICE_DATA, LOAD_DEVICE_DATA),
    takeLatest(actions.LOAD_VIDEO_LOGS, LOAD_VIDEO_LOGS),
    takeLatest(actions.GETVIDEOSFILTER, GETVIDEOSFILTER),
    takeLatest(actions.GET_WALLET, GET_WALLET),
    takeLatest(actions.LOAD_WALLET_HISTORY, LOAD_WALLET_HISTORY),
    takeLatest(actions.FILTER_WALLET_HISTORY, FILTER_WALLET_HISTORY),
    takeLatest(actions.LOAD_BANK_INFO, LOAD_BANK_INFO),
    takeLatest(actions.UPDATE_CUSTOMER_BANK, UPDATE_CUSTOMER_BANK),
  ])
}
