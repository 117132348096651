import actions from './actions'

const initialState = {
  requestInfo: null,
  data: null,
  selectedData: null,
  searchCustomer: null,

  displayUpdateDrawer: null,
  updating: false,
}

export default function subrequestReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    case actions.SHOW_INFO:
      return { ...state, selectedData: action.payload.RequestInfo, displayUpdateDrawer: true }
    case actions.HIDE_INFO:
      return { ...state, selectedData: null, displayUpdateDrawer: false }
    default:
      return state
  }
}
