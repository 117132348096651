import actions from './actions'

const initialState = {
  payoutsInfo: null,
  data: null,
  roledata: null,
  selectedData: null,
  newlyAdded: null,
  currentStatus: null,

  searchPayouts: null,
  searchDevice: null,
  search: null,
  company_data: null,

  payoutHistoryRes: null,
  displayPayoutHistoryDrawer: false,
  showing: false,


  displayPayoutStatusModal: false,
  changing: false,
}

export default function payoutsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }

    case actions.SHOW_HISTORY:
      return { ...state,selectedData: action.payload.payoutInfo, displayPayoutHistoryDrawer: true }
    case actions.HIDE_HISTORY:
      return { ...state, selectedData: null, displayPayoutHistoryDrawer: false }

      case actions.SHOW_MODAL:
      return { ...state,selectedData: action.payload.payoutInfo, displayPayoutStatusModal: true }
    case actions.HIDE_MODAL:
      return { ...state, selectedData: null, displayPayoutStatusModal: false }

    default:
      return state
  }
}
