import apiClient from 'services/axios'

const BASE_URL = 'api/configuration'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/get-configuration`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
export async function updateSettings(payload) {
  return apiClient
    .post(`${BASE_URL}/update-settings`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}