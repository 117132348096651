import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects'
import { loadAll, update, validate } from 'api/subscriptionrequest'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { subRequestInfo } = response.data
    console.log("subRequestInfo", subRequestInfo)
    yield put({
      type: actions.SET_STATE,
      payload: {
        requestInfo: subRequestInfo,
      },
    })
  }
}

export function* VALIDATE_REQUEST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { requestId } = payload
  const { response } = yield call(validate, requestId, payload)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_INFO,
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: true,
    },
  })
  const { UseridSel, updatedData } = payload
  const { response } = yield call(update, UseridSel, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      updating: false,
    },
  })
  if (response) {
    const { subRequestInfo } = response.data
    yield put({
      type: actions.HIDE_UPDATE,
    })
    yield put({
      type: actions.SET_STATE,
      payload: {
        requestInfo: subRequestInfo,
      },
    })
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeEvery(actions.UPDATE, UPDATE),
    takeEvery(actions.VALIDATE_REQUEST, VALIDATE_REQUEST),
  ])
}
