import apiClient from 'services/axios'

const BASE_URL = 'api/subscription-request-web'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/get-subscription-request`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function update(payload) {
  return apiClient
    .post(`${BASE_URL}/update-subscription-request`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function validate(requestId, payload) {
  delete payload.requestId
  return apiClient
    .put(`${BASE_URL}/validate-subscription-request/${requestId}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
