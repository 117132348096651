export const staffRoles = [
  {
    key: 'superadmin',
    name: 'Support User',
    // hidden: true,
  },
  {
    key: 'admin',
    name: 'Admin',
  },
  {
    key: 'user',
    name: 'User',
  },
]

export const leadType = [
  {
    key: 'cold',
    name: 'Cold',
  },
  {
    key: 'warm',
    name: 'Warm',
  },
  {
    key: 'hot',
    name: 'Hot',
  },
]

export const NomineeInfo = [
  {
    key: 'spouse',
    name: 'Spouse',
  },
  {
    key: 'father',
    name: 'Father',
  },
  {
    key: 'mother',
    name: 'Mother',
  },
  {
    key: 'brother',
    name: 'Brother',
  },
  {
    key: 'sister',
    name: 'Sister',
  },
]





export const BusinessType = [
  {
    key: 'Bar',
    name: 'Bar',
  },
  {
    key: 'Cafe',
    name: 'Cafe',
  },
  {
    key: 'Restaurant',
    name: 'Restaurant',
  },
]

export const payoutStatus = [
  {
    key: 'request',
    name: 'Request Initiated',
  },
  {
    key: 'inprogress',
    name: 'In Progress',
  },
  {
    key: 'hold',
    name: 'On Hold',
  },
  {
    key: 'approved',
    name: 'Approved',
  },
  {
    key: 'settled',
    name: 'Settled',
  },
]


export const paymentType = [
  {
    key: '1',
    name: 'Cheque',
  },
  {
    key: '2',
    name: 'Account Transfer',
  },
  {
    key: '3',
    name: 'GPay',
  },
]
