import apiClient from 'services/axios'

const BASE_URL = 'api/customers'

export async function loadAll() {
  return apiClient
    .get(`${BASE_URL}/customers-list/device`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadSingleCustomer(payload) {
  return apiClient
    .get(`${BASE_URL}/customers-detail/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function showWallet(payload) {
  return apiClient
    .get(`${BASE_URL}/get-wallet-points/${payload?.userId}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateCustomer(business_id, payload) {
  return apiClient
    .put(`${BASE_URL}/update-customers-detail/${business_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadcustomerdevice(payload) {
  return apiClient
    .get(`${BASE_URL}/device-detail/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}


export async function loadcustomerbankdetails(payload) {
  return apiClient
    .get(`${BASE_URL}/bank-detail/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function updateCustomerBank(business_id, payload) {
  return apiClient
    .put(`${BASE_URL}/update-customers-bank/${business_id}`, payload)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}




export async function loadcustomervideologs(payload) {
  return apiClient
    .get(`${BASE_URL}/video-logs/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadcustomervideologs2(business_id, filter_date) {
  return apiClient
    .get(`${BASE_URL}/video-logs2/${business_id}/${filter_date}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadwallethistory(payload) {
  return apiClient
    .get(`${BASE_URL}/wallet-history/${payload}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export async function loadwallethistory2(business_id, filter_date) {
  return apiClient
    .get(`${BASE_URL}/wallet-history2/${business_id}/${filter_date}`)
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
