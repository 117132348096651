import { all, call, put, takeLatest } from 'redux-saga/effects'
import { loadAll, uploadvideo, remove, updateCost } from 'api/videos'
import actions from './actions'

export function* LOAD_ALL() {
  const { response } = yield call(loadAll)
  if (response) {
    const { videoList } = response.data
    yield put({
      type: actions.SET_STATE,
      payload: {
        videoInfo: videoList,
      },
    })
  }
}

export function* UPLOAD_VIDEO({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: true,
    },
  })
  const { response } = yield call(uploadvideo, payload)
  const { videoList } = response.data
  yield put({
    type: actions.SET_STATE,
    payload: {
      uploading: false,
      displayUploadVideo: false,
      videoInfo: videoList,
    },
  })
}

export function* REMOVE({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: true,
    },
  })
  const { _id } = payload
  const { response } = yield call(remove, _id)
  yield put({
    type: actions.SET_STATE,
    payload: {
      removing: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_DELETE,
    })
    yield call(LOAD_ALL)
  }
}

export function* UPDATE_COST({ payload }) {
  yield put({
    type: actions.SET_STATE,
    payload: {
      displaying: true,
    },
  })
  const { videoId, updatedData } = payload
  delete payload.videoId
  const { response } = yield call(updateCost, videoId, updatedData)
  yield put({
    type: actions.SET_STATE,
    payload: {
      displaying: false,
    },
  })
  if (response) {
    yield put({
      type: actions.HIDE_COST_DRAWER,
    })
    yield call(LOAD_ALL)
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(actions.LOAD_ALL, LOAD_ALL),
    takeLatest(actions.UPLOAD_VIDEO, UPLOAD_VIDEO),
    takeLatest(actions.REMOVE, REMOVE),
    takeLatest(actions.UPDATE_COST, UPDATE_COST),
  ])
}
