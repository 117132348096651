import React, { useEffect } from 'react'
import { connect, useDispatch } from 'react-redux'
import { Input, Form, Button, Select } from 'antd'
import { injectIntl } from 'react-intl'
import CustomerAction from 'redux/customers/actions'
import MaskedInput from 'antd-mask-input'
import { NomineeInfo } from 'utils/data'
import { phoneNumberE164, phoneNumberNational } from 'utils/parser'
import Loading from 'components/app/common/Loading'
import CardTitle from 'components/app/common/CardTitle'

const { Option } = Select


const mapStateToProps = ({ dispatch, customers, user }) => ({
  dispatch,
  customers,
  user,
})

const UpdateUser = ({ customers, intl: { formatMessage } }) => {
  const dispatch = useDispatch()
  const path = window.location.pathname

  const userId = path.replace('/manage/customer-info/', '')

  const onFinish = values => {
    if (values.mobile) values.mobile = phoneNumberE164(values.mobile)
    dispatch({
      type: CustomerAction.UPDATE_CUSTOMER_BANK,
      payload: {
        updatedData: values,
        UseridSel: userId,
      },
    })
  }

  const [form] = Form.useForm()

  const toInputLowercase = e => {
    e.target.value = e.target.value.toLowerCase()
  }

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: CustomerAction.LOAD_BANK_INFO,
        payload: userId,
      }) // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    const apiCallInfo = customers?.bankInfo
    if (apiCallInfo) {
      form.setFieldsValue({
        account_holder_name: customers?.bankInfo?.account_holder_name,
        bank_name: customers?.bankInfo?.bank_name,
        account_number: customers?.bankInfo?.account_number,
        ifsc_code: customers?.bankInfo?.ifsc_code,
        name: customers?.bankInfo?.nominee_info?.name,
        relationship: customers?.bankInfo?.nominee_info?.relationship,
        mobile_number: phoneNumberNational(customers?.bankInfo?.nominee_info?.mobile_number),
      })
    }
  }, [form, customers])

  return (
    <div>
      <div className="card">
        <div className="card-body">
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            form={form}
            initialValues={
              {
                account_holder_name: customers?.bankInfo?.account_holder_name,
                bank_name: customers?.bankInfo?.bank_name,
                account_number: customers?.bankInfo?.account_number,
                ifsc_code: customers?.bankInfo?.ifsc_code,
                name: customers?.bankInfo?.nominee_info?.name,
                relationship: customers?.bankInfo?.nominee_info?.relationship,
                mobile_number: phoneNumberNational(customers?.bankInfo?.nominee_info?.mobile_number),
              }
            }
          >
            <CardTitle />
            <div className="row">
              <div className="col-12" hidden={customers?.detailInfo}>
                <Loading />
              </div>
            </div>
            <div className="row" hidden={!customers.detailInfo}>
              <div className="col-md-4">
                <Form.Item
                  name="account_holder_name"
                  label={formatMessage({ id: 'form.label.account_holder_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.account_holder_name' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="bank_name"
                  label={formatMessage({ id: 'form.label.bank_name' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.bank_name' })}
                    maxLength={64}
                    onInput={toInputLowercase}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="account_number"
                  label={formatMessage({ id: 'form.label.account_number' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.account_number' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="ifsc_code"
                  label={formatMessage({ id: 'form.label.ifsc_code' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.ifsc_code' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="name"
                  label={formatMessage({ id: 'form.label.nomineename' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder={formatMessage({ id: 'form.placeholder.nomineename' })}
                    maxLength={64}
                  />
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="relationship"
                  label={formatMessage({ id: 'form.label.relationship' })}
                  rules={[
                    { required: true, message: formatMessage({ id: 'form.error.required' }) },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder={formatMessage({ id: 'form.placeholder.relationship' })}
                    size="large"
                  >
                    {NomineeInfo?.map(o => (
                      <Option key={o.key} value={o.key}>
                        {o.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <div className="col-md-4">
                <Form.Item
                  name="mobile_number"
                  label={formatMessage({ id: 'form.label.phoneoptional' })}
                  rules={[
                    () => ({
                      validator(rule, value) {
                        const phoneNumber = phoneNumberNational(value)
                        if (!value || (phoneNumber && phoneNumber.length === 14)) {
                          return Promise.resolve()
                        }
                        return Promise.reject(
                          new Error(formatMessage({ id: 'form.error.phone.invalid' })),
                        )
                      },
                    }),
                  ]}
                >
                  <MaskedInput
                    mask="(111) 111-1111"
                    placeholder={formatMessage({ id: 'form.placeholder.mobile' })}
                    size="large"
                  />
                </Form.Item>
              </div>
            </div>
            <Form.Item className="text-right">
              <Button type="primary" htmlType="submit" size="large" loading={customers?.updating}>
                {formatMessage({ id: 'action.update' })}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(UpdateUser))
