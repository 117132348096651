import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import staff from './staff/sagas'
import customers from './customers/sagas'
import subscriptions from './subscriptions/sagas'
import subrequest from './subrequest/sagas'
import videos from './videos/sagas'
import payouts from './payouts/sagas'
import configurations from './configurations/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    staff(),
    customers(),
    subscriptions(),
    subrequest(),
    videos(),
    payouts(),
    configurations(),
  ])
}
