import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Helmet } from 'react-helmet'
import { Tabs, Tag } from 'antd'
import { Link } from 'react-router-dom'
import { phoneNumberNational } from 'utils/parser'
import CustomerAction from 'redux/customers/actions'
import CustomerDetail from './CustomerDetail'
import BankDetail from './BankDetail'
import DeviceDetail from './DeviceDetail'
import VideoLogs from './VideoLogs'
import WalletHistory from './WalletHistory'

const { TabPane } = Tabs

const mapStateToProps = ({ dispatch, customers, user }) => ({
  dispatch,
  customers,
  user,
})

const StoreInfo = ({ dispatch, customers, intl: { formatMessage } }) => {
  const path = window.location.pathname

  const userId = path.replace('/manage/customer-info/', '')

  useEffect(() => {
    if (userId !== null)
      dispatch({
        type: CustomerAction.LOAD_CUSTOMER_INFO,
        payload: userId,
      })
    return () => {
      dispatch({
        type: CustomerAction.SET_STATE,
        payload: {
          search: null,
        },
      })
    } // eslint-disable-next-line
  }, [dispatch])

  const query = new URLSearchParams(window.location.search)

  const tabname = query.get('tab')

  let tabUrlVal

  if (tabname === 'customer-info') {
    tabUrlVal = '1'
  } else if (tabname === 'device-info') {
    tabUrlVal = '2'
  } else if (tabname === 'video-logs') {
    tabUrlVal = '3'
  } else if (tabname === 'wallet-history') {
    tabUrlVal = '4'
  }
  else if (tabname === 'bank-info') {
    tabUrlVal = '5'
  }

  const phoneNumber = phoneNumberNational(customers?.detailInfo?.mobile_number)

  return (
    <div>
      <Helmet
        title={formatMessage({
          id: 'title.helmet.customer',
        })}
      />
      <div className="row">
        <div className="col-md-12">
          <h5 className="mb-4">
            <strong><span style={{ textTransform: 'capitalize' }}>{`${customers?.detailInfo?.first_name} ${customers?.detailInfo?.last_name}`}</span> - {`${phoneNumber} - Android`}</strong>
            &nbsp;
            {customers?.detailInfo?.status === true && <Tag color="green">Active</Tag>}
            {customers?.detailInfo?.status === false && <Tag color="red">InActive</Tag>}
          </h5>
          {customers?.detailInfo?.email_verified === true && (
            <>
              <Tag color="green" style={{ textTransform: 'capitalize' }}>
                Email Verified
              </Tag>
            </>
          )}
          {customers?.detailInfo?.email_verified !== true && (
            <>
              <Tag color="red" style={{ textTransform: 'capitalize' }}>
                Email Not Verified
              </Tag>
            </>
          )}
          {customers?.detailInfo?.mobile_verified === true && (
            <>
              <Tag color="green" style={{ textTransform: 'capitalize' }}>
                Mobile Verified
              </Tag>
            </>
          )}
          {customers?.detailInfo?.mobile_verified !== true && (
            <>
              <Tag color="red" style={{ textTransform: 'capitalize' }}>
                Mobile Not Verified
              </Tag>
            </>
          )}
        </div>
      </div>
      <Tabs defaultActiveKey={tabUrlVal}>
        <TabPane
          tab={
            <Link to="?tab=customer-info">
              <span>{formatMessage({ id: 'text.storeino.tab.customerInfo' })}</span>
            </Link>
          }
          key={1}
        >
          <CustomerDetail />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=bank-info">
              <span>{formatMessage({ id: 'text.storeino.tab.BankDetail' })}</span>
            </Link>
          }
          key={5}
        >
          <BankDetail />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=device-info">
              <span>{formatMessage({ id: 'text.storeino.tab.deviceInfo' })}</span>
            </Link>
          }
          key={2}
        >
          <DeviceDetail />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=video-logs">
              <span>{formatMessage({ id: 'text.storeino.tab.VideoLogs' })}</span>
            </Link>
          }
          key={3}
        >
          <VideoLogs />
        </TabPane>
        <TabPane
          tab={
            <Link to="?tab=wallet-history">
              <span>{formatMessage({ id: 'text.storeino.tab.WalletHistory' })}</span>
            </Link>
          }
          key={4}
        >
          <WalletHistory />
        </TabPane>
      </Tabs>
    </div>
  )
}

export default connect(mapStateToProps)(injectIntl(StoreInfo))
